
import * as cloudflareRuntime$EkEhuJ2Ok4 from '/home/circleci/project/node_modules/@nuxt/image/dist/runtime/providers/cloudflare'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536,
    "3xl": 1920,
    "4xl": 2560
  },
  "presets": {},
  "provider": "cloudflare",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['cloudflare']: { provider: cloudflareRuntime$EkEhuJ2Ok4, defaults: {"baseURL":"https://o7web.com"} }
}
        